@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Tajawal-Medium';
  src: url('../public/fonts/Tajawal-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.font-tajwal-medium {
  font-family: 'Tajawal-Medium', 'sans-serif';
}

p,
a,
h1,
h2,
h3,
span,
div {
  font-family: 'Tajawal-Medium', 'sans-serif';
}

.react-select__control .react-select__value-container,
.react-select__indicators,
.react-select__menu,
.react-select__menu-list .react-select__option,
.react-select__single-value {
  background-color: #141e3c !important;
  color: white !important;
  border: 0px solid white;
}

.react-select__control {
  border: 1px solid #141e3c;
  border-radius: 5px !important;
}
